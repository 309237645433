<template>
  <div>
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind" style="text-align: right">{{ list.Score }}积分</div>
      <div class="rikind">{{ list.AKName }}</div>
      <!-- <div v-if="list.AImg" class="essayImg">
        <img :src="list.AImg" alt="" />
      </div> -->
      <div
        class="essayContent"
        v-html="list.Content"
        @click="imageEnlargement"
      ></div>
      <div class="video" v-if="list.Annex">
        <!-- <a :href="list.Annex">{{ list.Annex }}</a> -->
        <video style="width: 100%" :src="list.Annex" controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div style="position: fixed; bottom: 0; width: 95%">
        <van-button class="button" @click="Activity" round type="info"
          >活动报名</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
var formatNum = function (num) {
  return num < 10 ? "0" + num : num;
};
import { ImagePreview } from "vant";
import { WxGetActivityDetail, WxEnrolPBActivity } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from "vant";
import Pdfh5 from "pdfh5";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      list: {}, //活动详情
      aids: 0, //活动aid
      ActivityFrom: {}, //活动申请提交参数
      pdf: "",
      nowDate: "",
      pdfh5: "",
    };
  },
  created() {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
  },
  methods: {
    // 富文本图片内容 点击放大
    imageEnlargement(e) {
      if (e.target.nodeName === "IMG") {
        ImagePreview({
          images: [e.target.src], // 传递的是图片集数组
          startPosition: 0, // 图片索引位置
          closeable: true, // 是否显示关了按钮
          showIndicators: true, // 是否显示轮播指示器
          loop: false, // 不设置为false,首图和尾部图不能正常加载
        });
      }
    },
    // 获取当前时间
    dataNow() {
      var nowDate = new Date();
      var year = nowDate.getFullYear();
      var month = formatNum(nowDate.getMonth() + 1);
      var day = formatNum(nowDate.getDate());
      var hour = formatNum(nowDate.getHours());
      var min = formatNum(nowDate.getMinutes());
      var sec = formatNum(nowDate.getSeconds());
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate(),
      };
      this.nowDate =
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
      console.log(this.nowDate);
    },
    // 获取活动详情
    getRealInfo() {
      this.aids = this.$route.params.Id;
      WxGetActivityDetail({ aId: this.aids }).then((res) => {
        this.list = res.data.data;
        // this.pdf = 'https://api.qxzhqm.cn//upload/files/2022/3/ThingJS-X应用模板制作标准.pdf'
        // this.list.Content = this.list.Content.replace(/<.*?>/ig,"")
        // console.log(this.list.Content);
        this.pdf = "/upload/files/2022/3/ThingJS-X应用模板制作标准.pdf";
        // this.pdf = '/upload/files/2022/3/20211217-老城区民营经济综合服务平台（PC端）(1)(1).docx'
        console.log(this.pdf);
        this.$nextTick(() => {
          this.pdfh5 = new Pdfh5("#demo", {
            pdfurl: "../api" + this.pdf,
          });
        });
        //监听完成事件
        this.pdfh5.on("complete", function (status, msg, time) {
          console.log(
            "状态：" +
              status +
              "，信息：" +
              msg +
              "，耗时：" +
              time +
              "毫秒，总页数：" +
              this.totalNum
          );
        });
      });
      this.activated();
    },
    activated() {
      var _this = this;
      _this.uuid = _this.$route.query.uuid;
      var shareUrl = global.BASE_SHARE_URL + "grade?uuid=" + _this.uuid;
      this.$wxShare.updateWxShareConfig({
        title: "标题",
        desc: "简介",
        link: shareUrl,
      });
    },
    // 报名参加活动
    Activity() {
      this.ActivityFrom.AId = this.list.AId;
      this.ActivityFrom.OpenID = getOpenId();
      // this.ActivityFrom.OpenID = "oebDsw5XNiVzsIUaP__HJXNUd6krs"
      WxEnrolPBActivity(this.ActivityFrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Dialog.alert({
            message: "提交成功!",
          }).then(() => {
            // on close
            this.$router.go(-1);
          });
        } else {
          Dialog.alert({
            message: "申请失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
  },
  mounted() {
    this.getRealInfo();
    this.dataNow();
  },
};
</script>
<style>
.button {
  width: 95%;
  margin: 15px 2.5%;
}
</style>